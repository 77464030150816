<template>
  <v-container
    :fluid="$vuetify.breakpoint.lgAndUp"
    class="fill-height d-flex flex-column"
    style="height: 90vh"
  >
    <v-row class="ma-0 pa-0 mb-6" align="center">
      <!-- ILUSTRAÇÃO -->
      <v-col
        cols="12"
        md="6"
        lg="4"
        xl="5"
        sm="12"
        class="d-flex flex-column align-center pt-0"
      >
        <v-img contain width="500" :src="successImage" />

        <div class="caption">
          <a
            href="https://br.freepik.com/vetores-gratis/ilustracao-do-conceito-de-coffee-break_10840251.htm#query=relax&position=6&from_view=author"
            >Imagem de storyset</a
          >
          no Freepik
        </div>
      </v-col>
      <!-- TEXTO -->
      <v-col cols="12" sm="12" md="6" lg="8" xl="7" class="d-flex flex-column">
        <v-card-title
          class="text-h5 font-weight-bold d-flex justify-center break-word text-center primary--text"
        >
          <span v-if="hasScheduleActivationInfo">
            {{ $ml.get("successful_schedule_activation_title") }}
            {{ formatDateAndHour(scheduleActivationInfo.activation_datetime) }}
          </span>
          <span v-else>
            {{ $ml.get("successful_activation_title") }}
          </span>
        </v-card-title>

        <v-list>
          <v-list-item v-for="(item, index) in activatedItemsList" :key="index">
            <v-list-item-icon class="mr-4">
              <v-icon
                v-text="item.icon ? item.icon : 'mdi-check-circle'"
                size="25"
                color="success"
              />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-html="$ml.get(item.title)"
                class="text-wrap break-word"
              />
              <v-list-item-subtitle
                v-if="item.subtitle"
                v-html="$ml.get(item.subtitle)"
                class="text-wrap break-word text-body-2"
              />
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <InlineFeatureRating class="mx-4" feature-slug="control_activation" />

        <v-card-actions
          :class="`mx-3 mt-6 ${
            $vuetify.breakpoint.smAndDown ? 'flex-column' : ''
          }`"
        >
          <v-btn
            v-if="!isSmallClient"
            x-large
            :block="$vuetify.breakpoint.xs"
            color="primary"
            :class="`text-none text-body-1 font-weight-medium pr-8 pl-5  ${
              $vuetify.breakpoint.smAndDown ? 'mb-4' : 'mr-4'
            }`"
            @click="primaryAction"
          >
            <v-icon v-text="primaryActionIcon" left size="20" class="mr-4" />
            {{ $ml.get(primaryActionText) }}
          </v-btn>

          <v-btn
            v-if="controlInTrial"
            x-large
            :block="$vuetify.breakpoint.xs"
            outlined
            color="primary"
            :class="`text-none text-body-1 font-weight-medium px-6`"
            @click="secondaryAction"
          >
            {{ $ml.get(secondaryActionText) }}

            <v-icon v-text="'mdi-arrow-right'" right class="ml-4" size="20" />
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import {
  today,
  redirectToTheCommercialManagerGoogleCalendar,
  formatDateAndHour,
} from "@/helpers/utils";

import { setFirebaseEvent } from "@/helpers/registerAnalytics";

import InlineFeatureRating from "@/components/general/InlineFeatureRating";

export default {
  name: "SuccessfulActivation",

  components: { InlineFeatureRating },

  data() {
    return {
      successImage: require("@/assets/images/control-activation.png"),
      today,
    };
  },

  computed: {
    ...mapGetters([
      "hasControlPlan",
      "company",
      "controlInTrial",
      "mainDomain",
      "currentUser",
      "hasScheduleActivationInfo",
      "scheduleActivationInfo",
      "isSmallClient",
      "isMicrosoft",
    ]),

    activatedItemsList() {
      const scheduleActivationItems = [
        {
          title: "successful_schedule_activation_description_title_1",
          subtitle: "successful_schedule_activation_description_subtitle_1",
        },
        {
          title: "successful_schedule_activation_description_title_2",
        },
        {
          title: "successful_schedule_activation_description_title_3",
          subtitle: "successful_activation_description_subtitle_3",
        },
        {
          title: "successful_schedule_activation_description_title_4",
        },
      ];

      const successActivationItems = [
        {
          title: "successful_activation_description_title_1",
        },
        {
          title: "successful_activation_description_title_2",
        },
        {
          title: "successful_activation_description_title_3",
          subtitle: "successful_activation_description_subtitle_3",
        },
      ];

      return this.hasScheduleActivationInfo
        ? scheduleActivationItems
        : successActivationItems;
    },

    primaryActionText() {
      if (this.controlInTrial) {
        return "schedule_meeting_button";
      }
      return "homepage";
    },

    primaryActionIcon() {
      if (this.controlInTrial) {
        return "mdi-calendar";
      }

      return "mdi-home";
    },

    secondaryActionText() {
      return "go_to_self_checkout";
    },
  },

  methods: {
    ...mapActions(["selfcheckoutRedirect"]),

    formatDateAndHour,

    goToSelfCheckout() {
      this.selfcheckoutRedirect();
    },

    primaryAction() {
      if (this.controlInTrial) {
        setFirebaseEvent(
          this.mainDomain,
          "schedule_meeting",
          "after_control_activation",
          this.currentUser.email
        );

        this.scheduleMeeting();
      } else {
        this.$router.push({ name: "Home" });
      }
    },

    secondaryAction() {
      setFirebaseEvent(
        this.mainDomain,
        "go_to_self_checkout",
        "after_control_activation",
        this.currentUser.email
      );

      this.goToSelfCheckout();
    },

    scheduleMeeting() {
      redirectToTheCommercialManagerGoogleCalendar();
    },
  },
};
</script>
